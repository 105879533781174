<template>
  <v-row>
    <v-col cols="12">
      <v-textarea v-if="$vuetify.breakpoint.mobile"
                  dense
                  rows="3"
                  label="* Descricao"
                  :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 100)
                  ]"
                  v-model.trim="value.descricao"/>
      <v-text-field v-else label="* Descricao"
                    dense
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 100)
                  ]"
                    v-model.trim="value.descricao"/>
      </v-col>
      <v-col cols="12" sm="6">
        <lookup-dominio label="* Tipo Curso"
                        :hidden-clear="true"
                        hint="Selecione o Tipo do Curso"
                        v-model="value.tipoCurso"
                        :type="lookups.arrTipoCurso"
                        :rules="[
                          this.$validators.notNullOrUndefined
                        ]"/>
      </v-col>
      <v-col cols="12" sm="6">
        <v-autocomplete label="* Dia Vencimento" dense
                        v-model="value.diaVencimento"
                        :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28]"
                        :rules="[
                                this.$validators.number.required,
                        v => this.$validators.number.greaterThanOrEquals(v, 1),
                        v => this.$validators.number.lessThanOrEquals(v, 31)
        ]"></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";

export default {
  name: "CursoForm",
  components: {LookupDominio},
  data() {
    return {
      lookups: {
        arrTipoCurso: ItemDeDominio.ARR_TIPO_CURSO,
      },
    }
  },
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>