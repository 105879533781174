var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"* Descricao","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); }
                ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}}):_c('v-text-field',{attrs:{"label":"* Descricao","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); }
                ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('lookup-dominio',{attrs:{"label":"* Tipo Curso","hidden-clear":true,"hint":"Selecione o Tipo do Curso","type":_vm.lookups.arrTipoCurso,"rules":[
                        this.$validators.notNullOrUndefined
                      ]},model:{value:(_vm.value.tipoCurso),callback:function ($$v) {_vm.$set(_vm.value, "tipoCurso", $$v)},expression:"value.tipoCurso"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"label":"* Dia Vencimento","dense":"","items":[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28],"rules":[
                              this.$validators.number.required,
                      function (v) { return this$1.$validators.number.greaterThanOrEquals(v, 1); },
                      function (v) { return this$1.$validators.number.lessThanOrEquals(v, 31); }
      ]},model:{value:(_vm.value.diaVencimento),callback:function ($$v) {_vm.$set(_vm.value, "diaVencimento", $$v)},expression:"value.diaVencimento"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }